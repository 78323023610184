export const DELAY_LOADING = 5000;
export const GOOGLE_MAP_API_KEY = 'AIzaSyDi_dm-l_R-yAXjmiPRK2YPleHMX1UnWPE';

export const EVENTS = [
  {
    title: 'Soirée Cocktail',
    date: '2022-09-12T19:00:00.000Z',
    description: 'Venez découvrir notre sélection de cocktails exclusifs.',
    image: '/images/logo.png',
  },
  {
    title: 'Concert Live',
    date: '2022-09-19T20:00:00.000Z',
    description: 'Profitez d\'une soirée musicale avec des artistes talentueux.',
    image: '/images/logo.png',
  },
  {
    title: 'Soirée à thème',
    date: '2022-09-26T21:00:00.000Z',
    description: 'Plongez dans une ambiance unique lors de notre soirée à thème.',
    image: '/images/logo.png',
  },
];
