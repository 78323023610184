import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ThemeProvider } from "@mui/material/styles";
import { FullPage, Slide } from "react-full-page";
import theme from "./theme";
import Header from "./components/Header/Header";


// import Events from './pages/Events/Events';
// import Gallery from './pages/Gallery/Gallery';
import Horaires from './pages/Horaires/Horaires';
import Map from "./pages/Map/Map";
import Presentation from "./pages/Presentation/Presentation";
// import Staff from "./pages/Staff/StaffPage";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { DELAY_LOADING } from "./constants";
// import GoogleMapComponent from "./components/Map/Map";

import "./App.css";
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, DELAY_LOADING);
  }, []);

  const transitionVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <div className="header">
          <Header />
        </div>
        <div className="content">
          <AnimatePresence>
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={transitionVariants}
                transition={{ duration: 0.5, when: "beforeChildren" }}
              >
                <FullPage>

              <Slide>
                <Presentation />
              </Slide>
                <Slide>
                    <Horaires /> 
                  </Slide>
                  <Slide>
                     <Map /> 
                  </Slide>


                </FullPage>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
