// Horaires.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import { Typography } from '@mui/material';

import backgroundImage from '../../assets/backgrounds/horaires2.png'; // Importez l'image de fond ici
import { StyledHorairesWrapper, StyledBox, ScheduleTable } from './HorairesStyles';
import HorairesSchedule from '../../components/HorairesSchedule/HorairesSchedule';

const Horaires = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledHorairesWrapper id="horaires" backgroundImage={backgroundImage}> {/* Pass backgroundImage as a prop */}
        <StyledBox>
          <Typography variant="h6" color="primary" className="title">
            Horaires d'ouverture
          </Typography>
          <hr className="separator" />
          <ScheduleTable>
            <HorairesSchedule />
          </ScheduleTable>
        </StyledBox>
      </StyledHorairesWrapper>
    </ThemeProvider>
  );
};

export default Horaires;
