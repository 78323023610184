// Presentation.js
import React from 'react';
import { Typography, Container, Avatar } from '@mui/material';
import { StyledBox, TextBackground } from './PresentationStyles';
import avatarImage from '../../assets/empty-avatar.png'; 


const loremIpsumText = `Lorem il velit lacinia, luctus lorem ac, cursus elit. Suspendisse id ullamcorper est. Fusce venenatis libero a sollicitudin aliquam. Sed sed ullamcorper neque. In rutrum orci velit, sit amet elementum justo imperdiet sit amet. Proin tincidunt facilisis ullamcorper. Integer sagittis eleifend neque, a aliquet enim faucibus vitae. Curabitur non odio non nulla gravida porttitor a id ipsum. Vestibulum interdum, lectus vel dignissim congue, velit nisl sollicitudin est, non dapibus neque ipsum in nunc. Nulla facilisi. Sed semper tincidunt placerat.`;

const numberOfBackgrounds = 2;
const backgrounds = [];

const importBackgrounds = async () => {
  for (let i = 1; i <= numberOfBackgrounds; i++) {
    const background = await import(`../../assets/backgrounds/bg${i}.png`);
    backgrounds.push(background.default);
  }
};

importBackgrounds();

const Presentation = () => {
  return (
    <StyledBox id="presentation" backgrounds={backgrounds}>
      <Container>
        <TextBackground>
          <Avatar
            src={avatarImage}
            alt="Avatar"
            style={{ margin: '0 auto', marginBottom: '20px', width: '120px', height: '120px' }} // Adjust width and height as needed
          />
          <Typography variant="h6" color="primary" className="title">
            Présentation
          </Typography>
          <hr className="separator" />

          <Typography variant="body1" color="text.secondary">
            {loremIpsumText}
          </Typography>
        </TextBackground>
      </Container>
    </StyledBox>
  );
};

export default Presentation;
