// MapContainerStyles.js
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

const MapContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, #171717 30%, #000000 90%)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  minHeight: `calc(100vh - 60px)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  transition: 'background-image 2.5s ease-in-out',
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  textTransform: 'none',
  backgroundColor: 'rgba(255, 165, 0, 0.7)', // Change transparency for a more vivid color
  '&:hover': {
    backgroundColor: 'rgba(255, 165, 0, 1)', // More transparent when hovered
  },
  border: '3px solid rgba(255, 165, 0, 1)', // Make border more vivid and thicker
  boxShadow: '1px 1px 10px rgba(255, 165, 0, 0.7)', // Make shadow more pronounced
  '&:active': { 
    boxShadow: '0px 0px 12px rgba(255, 165, 0, 1)', // Change shadow when button is clicked
    border: '3px solid rgba(255, 165, 0, 1)', // Keep border vivid when button is clicked
  },
}));


const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: theme.palette.text.primary,
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  border: '2px solid rgba(255, 165, 0, 0.5)',
  boxShadow: '1px 1px 8px rgba(255, 165, 0, 0.5)',

  marginBottom: theme.spacing(2),
}));

export { MapContainer, ButtonsContainer, StyledButton, StyledBox };
