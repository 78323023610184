// GoogleMapComponent.js
import React, { useCallback, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import mapStyles from './mapStyles';
import logo from '../../assets/logo.png';
import { StyledMapContainer, MapTextBackground } from './GoogleMapComponentStyles';
import { GOOGLE_MAP_API_KEY } from '../../constants'
const GoogleMapComponent = ({ latitude, longitude }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });
// eslint-disable-next-line
  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(mapInstance) {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ lat: latitude, lng: longitude });
    mapInstance.fitBounds(bounds);
    mapInstance.setZoom(10);
    setMap(mapInstance);
  }, [latitude, longitude]);

  const onUnmount = useCallback(function callback(_) {
    setMap(null);
  }, []);

  return (
    <div>
      <StyledMapContainer>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={{ lat: latitude, lng: longitude }}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{ styles: mapStyles }}
          >
            <Marker 
              position={{ lat: latitude, lng: longitude }} 
              icon={{
                url: logo,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          </GoogleMap>
        ) : <></>}
      </StyledMapContainer>
      <MapTextBackground>
        <Typography variant="h6">
          <FontAwesomeIcon icon={faMapMarkerAlt} /> 2 Rue Raspail, 87000 Limoges
        </Typography>
      </MapTextBackground>
    </div>
  );
};

GoogleMapComponent.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default GoogleMapComponent;
