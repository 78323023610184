import React from 'react';
import { Typography } from '@mui/material';

function MotivationalMessage() {
  const messages = [
    'Laissez-nous vous surprendre avec nos délicieux cocktails exotiques ! 🍹',
    'Installez-vous confortablement et profitez d\'une soirée inoubliable ! 🎉',
    'Notre équipe travaille dur pour vous offrir une expérience unique ! 💪',
    'Bienvenue dans notre univers de saveurs et d\'ambiance chaleureuse ! 🌟',
    'Prenez place et laissez-nous vous transporter dans un voyage gustatif ! 🌴',
    'Préparez-vous à découvrir des cocktails à la fois créatifs et rafraîchissants ! 🍸',
    'Détendez-vous, nous nous occupons de tout pour rendre votre soirée parfaite ! 😎',
    'Que diriez-vous de siroter un cocktail pendant que nous préparons votre commande ? 🍻',
    'Savourez des boissons préparées avec passion par nos mixologues experts ! 🍹',
    'Entrez dans notre univers et découvrez des cocktails uniques en leur genre ! 🌈',
  ];

  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };

  return (
    <Typography variant="body1">{getRandomMessage()}</Typography>
  );
}

export default MotivationalMessage;
