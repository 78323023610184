const schedule = [
    { day: 'Lundi', englishDay: 'Monday', hours: 'Fermé' },
    { day: 'Mardi', englishDay: 'Tuesday', hours: 'Fermé' },
    { day: 'Mercredi', englishDay: 'Wednesday', hours: 'Fermé' },
    { day: 'Jeudi', englishDay: 'Thursday', hours: '21:00 – 02:00' },
    { day: 'Vendredi', englishDay: 'Friday', hours: '21:00 – 02:00' },
    { day: 'Samedi', englishDay: 'Saturday', hours: '21:00 – 02:00' },
    { day: 'Dimanche', englishDay: 'Sunday', hours: '21:00 – 02:00' },
  ];
  
  export const getSchedule = () => {
    return schedule;
  };
  