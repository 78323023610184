import React from 'react';
import { getSchedule } from './HorairesSettings';
import { ScheduleRow, DayOfWeek, OpeningTime } from '../../pages/Horaires/HorairesStyles';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes } from '@fortawesome/free-solid-svg-icons';

const HorairesSchedule = () => {
  const schedule = getSchedule();
  const currentDay = moment().format('dddd');
  const currentTime = moment().format('HH:mm');

  const isOpen = (openingTime, closingTime) => {
    const currentTimeMoment = moment(currentTime, 'HH:mm');
    const openingTimeMoment = moment(openingTime, 'HH:mm');
    const closingTimeMoment = moment(closingTime, 'HH:mm');

    return currentTimeMoment.isBetween(openingTimeMoment, closingTimeMoment);
  };

  const getIcon = (hours) => {
    if (hours === 'Fermé') {
      return faTimes;
    }
    return faClock;
  };

  return (
    <>
      {schedule.map((item, index) => {
        if (item.englishDay.toLowerCase() === currentDay.toLowerCase()) {
          // console.log("Nous sommes " + item.day);
        }
        const isOpenNow = isOpen(item.openingTime, item.closingTime);

        return (
          <ScheduleRow
            key={index}
            isToday={item.englishDay.toLowerCase() === currentDay.toLowerCase()}
            isOpenNow={isOpenNow}
            isEven={index % 2 === 0}
          >
            <DayOfWeek>{item.day}</DayOfWeek>
            <OpeningTime>
              <FontAwesomeIcon icon={getIcon(item.hours)} /> {item.hours}
            </OpeningTime>
          </ScheduleRow>
        );
      })}
    </>
  );
};

export default HorairesSchedule;
