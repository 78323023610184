import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#171717',
      paper: '#1a1a1a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#dddddd',
    },
    primary: {
      main: '#d4af37',
      contrastText: '#000000',
    },
    secondary: {
      main: '#ff8800',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    // Ajoutez d'autres styles de typographie ici si nécessaire
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#222222',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          color: '#ffffff',
        },
      },
    },
    // Ajoutez d'autres personnalisations de composants ici si nécessaire
  },
  // Ajoutez des styles globaux ou des déclarations CSS personnalisées ici si nécessaire
  globals: {
    // ...
  },
});

export default theme;
