import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import MotivationalMessage from '../MotivationalMessage/MotivationalMessage';
import {
  LoadingOverlay,
  LogoContainer,
  Logo,
  MessageBox,
} from './LoadingScreenStyles';

import { DELAY_LOADING } from '../../constants';


const initialMotionState = { opacity: 0 };
const animateMotionState = { opacity: 1 };
const transitionMotionState = { duration: 0.5 };

function LoadingScreen() {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, DELAY_LOADING);

    return () => clearTimeout(timer);
  }, []);

  const renderMotivationalMessage = () => (
    <motion.div
      initial={initialMotionState}
      animate={animateMotionState}
      transition={transitionMotionState}
    >
      <MessageBox>
        <MotivationalMessage isVisible={showMessage} />
      </MessageBox>
    </motion.div>
  );

  return (
    <LoadingOverlay>
      <LogoContainer>
        <Logo src="/images/logo.png" alt="Logo" />
        {showMessage && renderMotivationalMessage()}
      </LogoContainer>
    </LoadingOverlay>
  );
}

export default LoadingScreen;
