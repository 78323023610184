import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  background: 'linear-gradient(180deg, #242424, #171717, #101010, #171717,#242424)',  // Changed the gradient to more subtle
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
}));

const LogoContainer = styled(Box)({
  textAlign: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const glow = keyframes`
  0%, 100% { 
    box-shadow: 
      0 0 10px #fff, 
      0 0 20px #fff, 
      0 0 30px #fff, 
      0 0 40px #FFA500; 
  }
  25% { 
    box-shadow: 
      0 0 20px #ffd700, 
      0 0 30px #ffd700, 
      0 0 40px #ffd700, 
      0 0 50px #FFA500, 
      0 0 60px #FFA500; 
  }
  50% { 
    box-shadow: 
      0 0 30px #ffff00, 
      0 0 40px #ffff00, 
      0 0 50px #FFA500, 
      0 0 60px #FFA500, 
      0 0 70px #FFA500; 
  }
  75% { 
    box-shadow: 
      0 0 20px #ffd700, 
      0 0 30px #ffd700, 
      0 0 40px #FFA500, 
      0 0 50px #FFA500, 
      0 0 60px #FFA500; 
  }
`;

const Logo = styled('img')(({ theme }) => ({
  height: '30%',   // Changed from 300px to 30%
  marginBottom: '10%',   // Changed from 20px to 2%
  opacity: 0.8,
  borderRadius: '50%',
  animation: `${glow} 2s ease-in-out infinite`,
  filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.3))',
  objectFit: 'contain', // To maintain aspect ratio of the image
  '@keyframes glow': {
    '0%': { transform: 'scale(1)', opacity: 0.8 },
    '50%': { transform: 'scale(1.1)', opacity: 1 },
    '100%': { transform: 'scale(1)', opacity: 0.8 },
  },
}));

const MessageBox = styled(Box)(({ theme }) => ({

  backgroundColor: '#171717',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  color: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid gold',
  margin: '15px 10px',
}));

export { LoadingOverlay, LogoContainer, Logo, MessageBox };
