// HorairesStyles.js
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledHorairesWrapper = styled('div')(({ theme, backgroundImage }) => ({ // Add backgroundImage as a prop
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: theme.palette.text.primary,
  textAlign: 'center',
  minHeight: `calc(100vh - 60px)`,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  transition: 'background-image 2.5s ease-in-out',
}));
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(23, 23, 23, 0.9)', // Change background color to a variation of #171717
  color: theme.palette.text.primary,
  padding: theme.spacing(4),
  width: '80vw',
  borderRadius: theme.spacing(1),
  border: '2px solid rgba(255, 165, 0, 0.5)', // Initial border color
  boxShadow: '1px 1px 8px rgba(255, 165, 0, 0.5)', // Initial shadow
  transition: 'transform 1.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    border: '2px solid rgba(255, 165, 0, 1)', // When hovered, make the border the same yellow but fully opaque
    boxShadow: '2px 2px 10px rgba(255, 165, 0, 1)', // Increase shadow when hovered and make it fully opaque
  },
}));


const ScheduleTable = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ScheduleRow = styled(Box)(({ theme, isToday, isOpenNow, isEven }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: isToday
    ? 'rgba(255, 165, 0, 0.5)'
    : isEven
    ? 'rgba(255, 165, 0, 0.1)'
    : 'transparent',
  borderRadius: theme.spacing(1),
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
  '&:hover': {
    backgroundColor: isToday
      ? 'rgba(255, 165, 0, 0.8)'
      : isEven
      ? 'rgba(255, 165, 0, 0.18)'
      : 'rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
  ...(isOpenNow && {
    border: `2px solid ${theme.palette.primary.main}`,
  }),
}));

const DayOfWeek = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const OpeningTime = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

export { StyledHorairesWrapper, StyledBox, ScheduleTable, ScheduleRow, DayOfWeek, OpeningTime };
