const menuLinks = [
    { id: 1, text: 'Accueil', href: '#accueil' },
    { id: 2, text: 'À propos', href: '#apropos' },
    { id: 3, text: 'Carte des boissons', href: '#carte' },
    { id: 4, text: 'Événements', href: '#evenements' },
    { id: 5, text: 'Galerie', href: '#galerie' },
    { id: 6, text: 'Contact', href: '#contact' },
  ];
  
  export default menuLinks;
  