// PresentationStyles.js
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(({ backgrounds, ...props }) => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [backgrounds]);

  return (
    <Box {...props} style={{ backgroundImage: `url(${backgrounds[backgroundIndex]})` }} />
  );
})(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: theme.palette.text.primary,
  textAlign: 'center',
  minHeight: '100vh', 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  transition: 'background-image 2.5s ease-in-out',
}));

const TextBackground = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: theme.palette.text.primary,
  padding: theme.spacing(4),

  borderRadius: theme.spacing(1),
  border: '2px solid rgba(255, 165, 0, 0.5)',
  boxShadow: '1px 1px 8px rgba(255, 165, 0, 0.5)',
  transition: 'transform 1.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
}));

export { StyledBox, TextBackground };
