import { css } from '@mui/system';

const HeaderStyles = {
  logo: css`
    height: 50px;
    margin-right: 10px;
  `,
  iconButton: css`
    color: #ffffff;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `,
  appBar: css`
  position: fixed;
  background: linear-gradient(180deg, #171717, #222);
  border: 2px solid rgba(255,165,0,0.23);
  min-height: 60px;
  max-height: 60px;
  z-index: 100; /* Ajout de z-index pour être au-dessus du contenu */


  `,
  typography: css`
    color: #ffffff;
  `,
};

export default HeaderStyles;
