// GoogleMapComponentStyles.js
import { styled } from '@mui/system';

export const StyledMapContainer = styled('div')({
  height: '60vh',
  width: '95vw',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '10px',
  border: '2px solid rgba(255, 165, 0, 0.5)',
  boxShadow: '1px 1px 8px rgba(255, 165, 0, 0.3)',

});

export const MapTextBackground = styled('div')(({ theme }) => ({
  fontFamily: 'Roboto',
  backgroundColor: 'rgba(23, 23, 23, 0.9)', // Changed from white to a variation of #171717
  color: 'white', // Changed the text color to white for better contrast
  border: '2px solid rgba(255, 165, 0, 0.5)', // Kept the border with a hint of the yellow color
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  transition: 'transform 1.3s ease-in-out, box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(255, 165, 0, 0.4)', // Changed the shadow to match the border color
    border: '2px solid rgba(255, 165, 0, 1)', // Kept the border color transition to full opacity when hovered
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
}));


