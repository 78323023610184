import React from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faDirections } from '@fortawesome/free-solid-svg-icons';
import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent';
import {MapContainer, ButtonsContainer, StyledButton } from './MapStyles';

const Map = () => {
  const address = '2 Rue Raspail, Limoges';
  const latitude = 45.8290304;
  const longitude = 1.2637391;

  const openGoogleMaps = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`);
  };

  const openWaze = () => {
    window.open(`https://www.waze.com/ul?q=${encodeURIComponent(address)}`);
  };

  const openAppleMaps = () => {
    window.open(`https://maps.apple.com/?q=${encodeURIComponent(address)}`);
  };

  return (
    
    <MapContainer>

          <Typography variant="h6" color="primary" className="title">
            Nous trouver
          </Typography>

      <GoogleMapComponent latitude={latitude} longitude={longitude} />
      <ButtonsContainer>
        <StyledButton variant="contained" onClick={openGoogleMaps} startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}>
          Google Maps
        </StyledButton>
        <StyledButton variant="contained" onClick={openWaze} startIcon={<FontAwesomeIcon icon={faDirections} />}>
        Waze
        </StyledButton>
        <StyledButton variant="contained" onClick={openAppleMaps} startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}>
         Apple Maps
        </StyledButton>
      </ButtonsContainer>

    </MapContainer>
  );
};


export default Map;
