import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { FaBars, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import { styled } from '@mui/system';
import HeaderStyles from './HeaderStyles';
import menuLinks from './menuLinks';

const Logo = styled('img')(HeaderStyles.logo);

const IconButtonStyled = styled(IconButton)(HeaderStyles.iconButton);

const AppBarStyled = styled(AppBar)(HeaderStyles.appBar);

const TypographyStyled = styled(Typography)(HeaderStyles.typography);

const Header = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header>
      <AppBarStyled>
        <Toolbar>
          <Logo src="./images/logo.png" alt="Logo" />
          <TypographyStyled variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LOGO
          </TypographyStyled>
          <IconButtonStyled edge="start" color="inherit" aria-label="menu" onClick={() => setIsMenuOpen(true)}>
            <FaBars size={24} />
          </IconButtonStyled>
        </Toolbar>
      </AppBarStyled>

      <Drawer anchor="right" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <List>
          {menuLinks.map((link) => (
            <ListItem button key={link.id}>
              <ListItemText primary={link.text} />
            </ListItem>
          ))}
          <ListItem>
            <IconButtonStyled href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookSquare size={24} />
            </IconButtonStyled>
            <IconButtonStyled href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagramSquare size={24} />
            </IconButtonStyled>
          </ListItem>
        </List>
      </Drawer>
    </header>
  );
};

export default Header;
